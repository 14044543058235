/**
 * @mixin
 */
export default {
    data: () => ({
        getMenuItemsToSidebarURL: "/v1/Menus?$orderby=MenuOrder&$filter=MenuParentId%20eq%20null&$expand=InverseMenuParent($orderby=MenuOrder)",
        getMenuSearch: "/v1/Menus?$filter=MenuParentId%20eq%20null",
        GetContactByMail1: "/v1/Contacts?$filter=contactEmailOffice%20eq%20'",
        GetContactByMail2: "'&$select=contactLastName,contactFirstName,contactEmailOffice,contactFullName,id,contactPhoneLandLine,contactPhoneMobileOffice,contactPhoneMobilePrivate,contactAddress1,contactAddress2,contactAddress3",
        logoutUrl: "/v1/Users/Logout"
    }),
    computed: {},
    methods: {}
};