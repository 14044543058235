import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import Trend from "vuetrend";
import { ClientTable } from "vue-tables-2";
import "@progress/kendo-ui";
import "@progress/kendo-ui/js/messages/kendo.messages.fr-FR.js";
import "@progress/kendo-ui/js/cultures/kendo.culture.fr-FR.js";

import "@progress/kendo-ui/js/messages/kendo.messages.en-US.js";
import "@progress/kendo-ui/js/cultures/kendo.culture.en-US.js";
import "@/i18n/kendo.messages.fr-FR";

import "@progress/kendo-theme-default/dist/all.css";
import { TreeViewInstaller } from "@progress/kendo-treeview-vue-wrapper";
import KProgress from "k-progress";
import ToggleButton from "vue-js-toggle-button";
import { UploadInstaller } from "@progress/kendo-upload-vue-wrapper";
import { ValidatorInstaller } from "@progress/kendo-validator-vue-wrapper";
import { DataSourceInstaller } from "@progress/kendo-datasource-vue-wrapper";
import { DateinputsInstaller } from "@progress/kendo-dateinputs-vue-wrapper";
import { GridInstaller } from "@progress/kendo-grid-vue-wrapper";
import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
import {
  AutoComplete,
  MultiSelect,
  DropdownsInstaller,
} from "@progress/kendo-dropdowns-vue-wrapper";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import {
  ChartInstaller,
} from "@progress/kendo-charts-vue-wrapper";

import {
  EditorInstaller,
} from "@progress/kendo-editor-vue-wrapper";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-vue-intl";

Vue.component("localization", LocalizationProvider);
Vue.component("intl", IntlProvider);
Vue.use(EditorInstaller);
Vue.use(ChartInstaller);
Vue.component("numerictextbox", NumericTextBox);
Vue.component("k-input", Input);
Vue.use(Input);
Vue.use(GridInstaller);
Vue.use(DropdownsInstaller, AutoComplete, MultiSelect);
Vue.use(DataSourceInstaller);
Vue.use(DateinputsInstaller);
Vue.component("k-progress", KProgress);
Vue.use(ValidatorInstaller);
Vue.use(UploadInstaller);
Vue.use(ToggleButton);
Vue.use(TreeViewInstaller);
Vue.use(BootstrapVue);
Vue.use(ClientTable, { theme: "bootstrap4" });
Vue.use(Trend);
Vue.component("datepicker", DatePicker);
