
window["inventory-app-back-office"] =  window['inventory-app-back-office']  ||  {}
export default {
  name: "EditeInventairePage",
  components: {
    ...window["inventory-app-back-office"].default
  },
  data: () => ({
    language: "fr"
  })
};