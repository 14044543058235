import "babel-polyfill";
import "core-js/stable";
import 'regenerator-runtime/runtime' 
import "expose-loader?jQuery!jquery";
import "expose-loader?$!jquery";
import App from "./App.vue";
import Vue from "vue";
import Vuex from "vuex";
import { i18n } from "./i18n/i18n";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "./plugins/dscvlibs";
import store from "./store/index";
import router from "./router/index";
import globalConfig from "./mixins/globalConfig";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import VueAppInsights from "vue-app-insights";
import VueGoodWizard from "vue-good-wizard";
import VueSimpleAlert from "vue-simple-alert";
import VueSweetalert2 from "vue-sweetalert2";
import excel from "vue-excel-export";
import VueCookies from "vue-cookies";
import Axios from "axios";
import sanitizeHTML from "sanitize-html";
import { ButtonsInstaller } from "@progress/kendo-buttons-vue-wrapper";
import api from "@/utils/api";
import constant from "@/utils/constants";
import "@progress/kendo-ui";
import VueToastr from "vue-toastr";
import { GridInstaller } from "@progress/kendo-grid-vue-wrapper";
import { LayoutInstaller } from "@progress/kendo-layout-vue-wrapper";
import { NumericTextBox, Input } from "@progress/kendo-vue-inputs";
import {
  MaskedTextBox,
  InputsInstaller,
} from "@progress/kendo-inputs-vue-wrapper";
import { DateinputsInstaller } from "@progress/kendo-dateinputs-vue-wrapper";
import { DropdownsInstaller } from "@progress/kendo-dropdowns-vue-wrapper";
import { ValidatorInstaller } from "@progress/kendo-validator-vue-wrapper";
import { UploadInstaller } from "@progress/kendo-upload-vue-wrapper";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DialogInstaller } from "@progress/kendo-dialog-vue-wrapper";
import { DataSourceInstaller } from "@progress/kendo-datasource-vue-wrapper";
import JsonExcel from "vue-json-excel";
import "sweetalert2/dist/sweetalert2.min.css";
import { required, numeric } from "vee-validate/dist/rules";
import "vue-progress-path/dist/vue-progress-path.css";
import VueProgress from "vue-progress-path";

import {  extend, ValidationObserver, ValidationProvider } from "vee-validate";
import Default from "./layout/Default";
import NoSidebar from "./layout/NoSidebar";

Vue.component('default-layout', Default);
Vue.component('no-sidebar-layout', NoSidebar);
extend("required", {
  ...required,
  message: "{_field_} est obligatoire.",
});

extend("numeric", {
  ...numeric,
  message: "{_field_} doit être numérique.",
});

extend('usernamewithout@', {
  message: 'le nom d\'utilisateur ne doit pas contenir un @.',
  validate: value => !! value.match(/^((?!@).)*$/)
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.use(VueProgress, {
  defaultShape: "line",
});
Vue.use(VueSweetalert2);
Vue.use(ButtonsInstaller);
Vue.component("downloadExcel", JsonExcel);
Vue.component("dropdownlist", DropDownList);
Vue.component("maskedtextbox", MaskedTextBox);
Vue.use(DialogInstaller);
Vue.use(InputsInstaller);
Vue.use(ValidatorInstaller);
Vue.use(DateinputsInstaller);
Vue.use(GridInstaller);
Vue.use(LayoutInstaller);
Vue.use(DropdownsInstaller);
Vue.use(UploadInstaller);
Vue.use(DataSourceInstaller);
Vue.component("k-input", Input);
Vue.component("numerictextbox", NumericTextBox);

Vue.prototype.$api = api;
Vue.prototype.$constant = constant;

Vue.prototype.$sanitize = sanitizeHTML;

Vue.prototype.$http = Axios;
Vue.use(VueCookies);
Vue.use(excel);
Vue.use(VueToastr);
Vue.use(VueSimpleAlert);
Vue.use(VueGoodWizard);
Vue.use(VueAppInsights, {
  id: window.idAppInsight,
  router,
  property: null,
  IConfig: {
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
  },
  trackInitialPageView: true,
});

Vue.config.productionTip = false;
Vue.filter("formatDateFrench", function (value) {
  if (value) {
    return moment.utc(String(value)).local().format("DD-MM-YYYY HH:mm:ss");
  }
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY hh:mm");
  }
});
Vue.mixin(globalConfig);
Vue.use(Vuex);

new Vue({
  render: (h) => h(App),
  vuetify,
  store,
  router,
  i18n,
}).$mount("#app");
