window["aef-automate"] =  window['aef-automate']  ||  {}

export default {
    
    name:"DashboardView",
    components: {
    ...window["aef-automate"].default
  },
  data: () => ({
    language: ""
  }),
  mounted() {
    this.language = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;
  }
};
