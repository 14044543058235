<template>
  <div class="AdministrationReportPage">
    <Administration :token="token" :language="language" />
  </div>
</template>
<script>
window["multirapports"] = window["multirapports"] || {};
export default {
  name: "AdministartionPages",
  data: () => ({
    token: null,
    language: "",
  }),
  created() {
    this.token = sessionStorage.getItem("adal.idtoken");
  },
  components: {
    ...window["multirapports"].default,
  },
  mounted() {
    this.language = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;
  },
};
</script>
<style lang="scss">
.AdministrationReportPage {
  top: 73px;
  position: relative;
  z-index: 99;
  left: 200px;
  width: calc(100% - 180px);
  overflow-y: scroll;
  min-height: calc(100vh - 127px);
}
@media print {
  .AdministrationReportPage {
    left: 0px !important;
    overflow-y: hidden !important;
  }
}
@media (max-width: 480px) and (min-width: 320px) {
  .AdministrationReportPage {
    top: 107px;
    position: relative;
    z-index: 99;
    left: 46px;
    width: calc(100% - 34px);
  }
}
@media (max-width: 640px) and (min-width: 480px) {
  .AdministrationReportPage {
    top: 107px;
    position: relative;
    z-index: 99;
    left: 40px;
    width: calc(100% - 34px);
  }
}
@media (max-width: 768px) and (min-width: 640px) {
  .AdministrationReportPage {
    top: 107px;
    position: relative;
    z-index: 99;
    left: 40px;
    width: calc(100% - 34px);
  }
}
</style>
