<template>
  <div class="AccesDenied"></div>
</template>

<script>
export default {
  name: "AccesDenied",
  data: () => ({}),
  mounted() {
    this.$swal({
      title: this.$t("AccessDenied.DeniedAccess"),
      imageUrl: require("../assets/AccesDenied.png"),
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Erreur image",
      showConfirmButton: false,
    });
  },
  created: function () {},
  methods: {},
};
</script>
