<template>
   <div class="px-0 h-100 container-fluid maincontainer">
    <div>
      <DPMenu :titleHeader="titleHeaderC" 
              :UpdateUserInfo="updatedUserInfo" 
              :titleMenu="titleMenuC" 
              @switchLanguage="switchLanguage" 
              :language="language" 
              @showHomePage="showHomePage"
              :titleMenuFR="titleMenuFR"
              :titleMenuEN="titleMenuEN"
              />
    </div>
    <div class="pagecontent">
      <DPSidebar class=" col-3 col-sm-3 col-md-2 col-xs-12" :children="children" :language="ChangedLang"></DPSidebar>
      <div class="col-9 px-0 pb-0 col-sm-9 col-md-10 col-xs-12 maincontent">
        <router-view class="componentcontent pl-5 pb-5"></router-view>
        <DPFooter
            class="ro FooterContainer footercontent"
            :UserType="userType"
            :ExerciseMyRightsUrl="baseUrlExerciseMyRights"
          ></DPFooter>
      </div>
    </div>
  </div>
 </template>
 <script>
 import api from "../mixins/api";
 import endPoints from "../mixins/endPoints";
 import { mapState } from 'vuex';

 export default {
   name: "Default",
   mixins: [api, endPoints],
   components: { ...window["kpmg-app-layout"].default},
   data() {
     return {
       baseUrlExerciseMyRights: window.baseUrlExerciseMyRights,
       titleHeaderC: "Portal Discovery",
       titleMenuC: "Lanceur d'applications",
       language: "fr",
       SidebarMenu:[],
       baseUrlPortal: window.baseUrlPortal,
       apps: [],
       appsColumns:0,
       ChangedLang: "",
       titleMenuFR: "", 
       titleMenuEN: "", 
       openDeconnxion: false,
       userInformation: false,
       OnClickNotification : false,
       titleSousMenuFR: "",
       titleSousMenuEN: "",
       UrlTiers: window.baseUrlTiers,
       emailConnectedUser: "",
       updatedUserInfo: false,
       listOfUserInformation: [],
       userType: "",
     };
   },
   created: function() {      
     window.baseUrlExerciseMyRights = "https://privacyportaluatde.onetrust.com/webform/92b9d122-6dba-4680-bf01-0ece5d425ebd/7708a07b-7ac7-44d4-b29f-055229eb759c";
     this.emailConnectedUser = localStorage.getItem("connectedUserEmail");
     this.getinfoConnectedUser();
   },
   mounted: function(){
    if(this.$store.state.selectedMenu)
    {
      this.titleMenuFR = this.$store.state.selectedMenu.menuTitle;
      this.titleMenuEN = this.$store.state.selectedMenu.menuEnglishTitle;
    }
    this.userType = sessionStorage.getItem("userType");
    this.language = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;
        
     let tocken = sessionStorage.getItem("adal.idtoken");
     if (tocken) this.getDataFromTocken(tocken);
 
     if (
       window.location.href.includes("/edit") &&
       window.location.href.includes("/app/confirm/") &&
       this.refresh == 0
     ) {
       this.refresh = 1;
       window.location.href = "/app/confirm/list";
     }
     if (
       window.location.href.includes("/app/confirm/tdb/") &&
       this.refresh == 0
     ) {
       this.refresh = 1;
       window.location.href = "/app/confirm/list";
     }
     this.ChangedLang = this.$i18n.locale;
   },
   methods:{
     getinfoConnectedUser() {
       this.isRenewInformation = true;
       let accountsApiUrl = 
       this.UrlTiers +
           this.GetContactByMail1 +
           encodeURIComponent(this.emailConnectedUser) +
           this.GetContactByMail2; 
       this.get(accountsApiUrl)
       .then((res) => {
           this.updatedUserInfo = true;
           this.isRenewInformation = false;
           this.listOfUserInformation = res.data.value[0];
           localStorage.setItem('connectedUserFirstName', this.listOfUserInformation.contactFirstName == null ? '' : this.listOfUserInformation.contactFirstName);
           localStorage.setItem('connectedUserLastName', this.listOfUserInformation.contactLastName == null ? '' : this.listOfUserInformation.contactLastName);
           localStorage.setItem('contactPhoneLandLine', this.listOfUserInformation.contactPhoneLandLine == null ? '' : this.listOfUserInformation.contactPhoneLandLine);
           localStorage.setItem('contactAddress1', this.listOfUserInformation.contactAddress1 == null ? '' : this.listOfUserInformation.contactAddress1);          
           localStorage.setItem('ConnectedFullName', this.listOfUserInformation.contactFullName == null ? '' : this.listOfUserInformation.contactFullName);
       })
       .catch((err) => {
           this.isRenewInformation = false;
           window.console.log(err);
       });
   },
     getDataFromTocken(seessionStorageVar) {
       let base64Url = seessionStorageVar.split(".")[1];
       let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
       let jsonPayload = decodeURIComponent(
         atob(base64)
           .split("")
           .map(function(c) {
             return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
           })
           .join("")
       );
       // recupere l email a partir du tocken
       this.UserDataFromTocken = JSON.parse(jsonPayload);
       if (sessionStorage.getItem("isGigyaToken") == "true") {
         this.emailConnectedUser = this.UserDataFromTocken.email;
       } else if (this.UserDataFromTocken.email !== null) {
         this.emailConnectedUser = this.UserDataFromTocken.email;
         localStorage.setItem("connectedUserEmail", this.emailConnectedUser);
       }
       if (this.emailConnectedUser != null) {
         if (this.emailConnectedUser.includes("@kpmg.fr")) {
           this.type = true;
           this.userType = "Collaborateur";
         } else {
           this.type = false;
           this.userType = "Client";
         }
       }
       localStorage.setItem("userType", this.userType);
     },
     showHomePage() {
       this.openDeconnxion = false;
       this.userInformation = false;
 
       this.OnClickNotification = false;
       this.openMenu = false;
       if(window.location.pathname != '/')
         this.$router.push("/");
     },
     switchLanguage(event) {
       this.$i18n.locale = event;
       this.ChangedLang = event;
       this.language = event;
       sessionStorage.setItem("UserLang", event);
       localStorage.setItem("UserLang", event);
 
       this.openDeconnxion = false;
       if (event == "fr") {
         // eslint-disable-next-line no-undef
         kendo.culture("fr-FR");
       }
       if (event == "en") {
         // eslint-disable-next-line no-undef
         kendo.culture("en-US");
       }
     },
     genericLogAppInsight(arg) {
       this.$appInsights.trackEvent(
         "Portal ::" + arg.methodeName + "",
         {
           name: arg.methodeName,
           user: this.ConnectedUserEmail,
           Module: "Portal",
           Status: arg.Statut,
           Permission: JSON.stringify(this.scopesData),
           Feature: "Suivi",
           Action: arg.Action,
           Execution: "Front",
           userType: this.ConnecteduserType,
           LogLevel: arg.LogLevel,
           Message: arg.Message,
           AccessedMenuCode: arg.AccessedMenuCode
         }
       );
     },
   },
   watch: {
     ChangedLang() {},
     sideBar(){},
     children(){},
     selectedSubMenu(newValue) {
      if(this.$store.state.selectedMenu)
      {
        this.titleMenuFR = this.$store.state.selectedMenu.menuTitle;
        this.titleMenuEN = this.$store.state.selectedMenu.menuEnglishTitle;
      }
    },
   },
   computed:{
    children(){
      return this.$store.state.selectedMenu != null ? this.$store.state.selectedMenu.inverseMenuParent || [] : [];
    },
    ...mapState(['selectedSubMenu']),
   }
 };
 </script>
 <style scoped>
 .pagecontent{
   flex-shrink: 0;
   flex-grow: 1;
   display: flex;
 }
 .maincontainer{
   display: flex;
   flex-direction: column;
   align-items: stretch;
 }
 .maincontent{
   min-height: 100%;
   display: flex;
   flex-direction: column;
   align-items: stretch;
 }
 .componentcontent{
   flex-shrink: 0;
   flex-grow: 1;
 }
 .footercontent{
   flex-shrink: 0;
 }
 .DPHome {
   padding: 32px;
   top: 117px;
   left: 160px;
   text-align: left;
   width: 100%;
   font-family: Open Sans, sans-serif;
   font-weight: bold;
   height: calc(100vh - 72px - 100px - 64px);
 }
 .name {
   color: #00338d;
   font-size: 22px;
   font-style: normal;
   font-family: open sans, sans-serif;
   font-weight: bold;
   padding-left: 200px;
   width: 70%;
 }
 @media (max-width: 480px) and (min-width: 320px) {
   /********home responsive */
   .DPHome {
     left: 23px !important;
     width: 362px !important;
     max-width: 100% !important;
     cursor: pointer !important;
   }
 }
 @media (max-width: 648px) and (min-width: 480px) {
   /******home responsive */
   .DPHome {
     left: 68px !important;
     width: 385px !important;
     max-width: 100% !important;
     cursor: pointer !important;
   }
 }
 </style>