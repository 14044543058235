window["kpmg-docsapi"] =  window['kpmg-docsapi']  ||  {}
export default {
  name: "DocsApiUploadPage",
  components: {
    ...window["kpmg-docsapi"].default
  },
  data: () => ({
    language: ""
  }),
  mounted() {
    this.language = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;
  }
};