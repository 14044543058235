<template>
  <div
    translate="no"
    class="notranslate"
    :class="{ 'custom-home-footer': $route.path.length == 1 }"
  >
    <v-app v-if="!loggedIn">
      <v-content>
        <LandingPage @loggedIn="OnLoggedIn"></LandingPage>
      </v-content>
    </v-app>
    <v-app 
      @AccesDenied="AccesDenied"
      :UserType="ConnectedUserType"
      v-if="
        loggedIn &&
        InDWH &&
        !hasNotAccess &&
        !isLoadingRES &&
        ((isClient && $store.state.consent) || !isClient)
      ">
      <component @showSidebar="showSidebar" :is="layout" :language="language" @AccesDenied="AccesDenied" >
        <div class="Router-content">
          <router-view class="layout-content" />
        </div>      
      </component>
    </v-app>
    <SessionControl
      @disconnectUser="disconnect"
      v-if="loggedIn && InDWH && !isLoadingRES"
    />
    <div class="LoaderBox" v-if="isLoadingRES && loggedIn">
      <div class="spinner-border SpinnerHome"></div>
    </div>
    <Consentement
      :Token="tokenConnectedUser"
      :BaseUrl="baseUrlConsent"
      v-on:AcceptConsent="Consent"
      v-if="loggedIn && InDWH && isClient"
    />
    <AccesDenied
      v-if="loggedIn && (!InDWH || hasNotAccess) && !isLoadingRES"
    ></AccesDenied>
  </div>
</template>

<script>
const default_layout = "default";

import api from "./mixins/api";
import endPoints from "./mixins/endPoints";
import { globalConfig } from "./plugins/globalConfig";
import AccesDenied from "./components/AccesDenied";
import { Eventbus } from "./mixins/Bus";

export default {
  name: "app",
  mixins: [endPoints, api],
  components: {
    ...window["onetrust-js-modules"].default,
    ...window["kpmg-app-layout"].default,
    ...window["screensetgigya"].default,
    AccesDenied,
  },
  data: () => ({
    children: [],
    uesrLang: "",
    baseUrlCgu: window.baseUrlCgu,
    idmKeyGigya: window.idmKeyGigya,
    baseUrlConsent: window.baseUrlConsent,
    UrlTiers: window.baseUrlTiers,
    UrlOrganisation: window.baseUrlOrganisation,
    loggedIn: false,
    language: "",
    ShowCookies: true,
    lang: "",
    ConnectedUserType: "",
    MenuData: [],    
    baseUrlPortal: window.baseUrlPortal,
    FooterData: [],
    img: require("@/assets/kpmg/KPMG_logo.png"),
    avatarimg: require("@/assets/kpmg/avatar.png"),
    frenchmg: require("@/assets/kpmg/drapfr2.png"),
    englishimg: require("@/assets/kpmg/drapang2.jpg"),
    ConnectedUserEmail: "",
    InDWH: false,
    isLoadingRES: true,
    type: false,
    isClient: false,
    userType: "",
    hasNotAccess: false,
    removeClass: "",
    selectedMenu: null,
    emailConnectedUser: ""
  }),
  created: function () {    
    this.emailConnectedUser = localStorage.getItem("connectedUserEmail");
    Eventbus.$on("token_changed", () => {
      this.checkToken();
    });
    this.checkToken();
    let tocken = sessionStorage.getItem("adal.idtoken");
    if (tocken) this.getDataFromTocken(tocken);
    if (sessionStorage.getItem("UserLang") == "") {
      this.uesrLang = localStorage.getItem("UserLang");
    }
  },
  computed: {
    layout(){
      return (this.$route.meta.layout || default_layout) + '-layout'; 
    },
    tokenConnectedUser() {
      return sessionStorage.getItem("adal.idtoken");
    },
    globalConfig() {
      return globalConfig;
    },
    ClientConsent() {
      return !this.isClient ? true : this.$store.state.consent;
    },
  },
  watch: {
    selectedMenu(){},
  },
  mounted() {
    if(localStorage.getItem("adal.idtoken"))
      this.getMenuItemsToSidebar();
    this.$i18n.locale = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;
    this.language = this.$i18n.locale;
  },
  methods: {    
    getMenuItemsToSidebar() {
      return this.get(this.baseUrlPortal + this.getMenuItemsToSidebarURL)
        .then((response) => {
          this.apps = response.data.value;
          this.appsColumns = Math.ceil(this.apps.length / 2);
          let url = window.location.pathname;
          let currentMenu;
          currentMenu = this.apps.find((acc) => {
          if (url === "/DynamicReports") return acc.menuCode === "DSHB20";
              acc.inverseMenuParent.some((a) =>
                decodeURI(a.menuLink).split("?")[0] ==
                decodeURI(url.concat(window.location.search).split("?")[0])
            );
          });
          
          if (currentMenu != undefined) {
            let currentSubMenu = currentMenu.inverseMenuParent.find((a) =>decodeURI(a.menuLink) == decodeURI(url.concat(window.location.search)));

            if (!currentSubMenu)
              currentSubMenu = currentMenu.inverseMenuParent.find((a) =>
                decodeURI(a.menuLink) ==
                decodeURI(url.concat(window.location.search)) ||
                decodeURI(a.menuLink).split("?")[0] ==
                decodeURI(url.concat(window.location.search).split("?")[0])
              );

            this.children = currentMenu.inverseMenuParent;
            if (currentSubMenu) {
              this.openedLink = currentSubMenu.menuLink;
              this.showActiveLink(currentMenu, this.openedLink);
            }
          } else {
            if (window.location.href.includes("/rapport?path=Discovery%2FApplication%2FV%C3%A9rification%20Email")) {
              currentMenu = this.apps.find((x) => x.inverseMenuParent.some((e) => e.menuCode == "ADM0.5"));
              this.showActiveLink(currentMenu.currentMenu);
            }
            if ( window.location.href.includes("/app/inventoryapp/AddInventaire")) {
              currentMenu = this.apps.find((x) => x.inverseMenuParent.some((e) => e.menuCode == "INV01"));
              this.showActiveLink(currentMenu.currentMenu);
            }
            if (window.location.href.includes("/app/fschecker/audit-folders")) {
              currentMenu = this.apps.find((x) => x.inverseMenuParent.some((e) => e.menuCode == "FSCK01"));
              this.showActiveLink(currentMenu.inverseMenuParent);
            }
            if (window.location.pathname === "/bookmark") {
              currentMenu = this.apps[0];
              if (currentMenu.inverseMenuParent.length > 1) {
                this.openedLink = currentMenu.inverseMenuParent[2].menuLink;
              } else {
                this.openedLink = currentMenu.inverseMenuParent[0].menuLink;
              }
              this.showActiveLink(this.apps[0]);
            }
          }
                   
          if(currentMenu == undefined)
          {
            currentMenu = this.apps.find((x) => {
              x.menuLink == url;
            });
          }

          if(currentMenu == undefined)
          {
            currentMenu = this.apps.find((x) => x.inverseMenuParent.some((e) => e.menuLink == url));
          }
          this.setDocumentTitle(currentMenu, url);
          this.$store.commit("UpdateSelectedMenu", currentMenu)
        })
        .catch((err) => {
          this.apps = err;
          this.AccesDenied();
        });
    },
    setDocumentTitle(currentMenu, url){
      if(currentMenu)
        {
          let currentSubMenu;
          if (url)
            currentSubMenu = currentMenu.inverseMenuParent.find((x) => x.menuLink == url );
          else 
           currentSubMenu = currentMenu.inverseMenuParent[0];
          if(currentSubMenu)
          {
            this.$store.commit("UpdateSelectedSubMenu", currentSubMenu);
            let titleSousMenuFR = currentSubMenu.menuTitle;
            let titleSousMenuEN = currentSubMenu.menuEnglishTitle;
            let ChangedLang = localStorage.getItem("UserLang")? localStorage.getItem("UserLang"): this.$i18n.locale;
            if (ChangedLang == "fr") {
              document.title = currentMenu.menuTitle + " - " + titleSousMenuFR;
            }
            if (ChangedLang == "en") {
              document.title = currentMenu.menuEnglishTitle + " - " + titleSousMenuEN;
            }
          }
        }
    },
    RouterBind: function (val) {
      let foundStyle = "";

      let genericLogAppInsight = (arg) => {
        this.$appInsights.trackEvent(
          "Portal::" + this.pagePath + "::/" + arg.methodeName + "",
          {
            name: this.pagePath + "::" + arg.methodeName + "",
            user: this.ConnectedUserEmail,
            Module: "Portal",
            methodeName: arg.methodeName,
            Status: arg.Statut,
            Permission: this.UserScopes,
            Feature: "Style",
            Action: "RouterBind",
            Execution: "Front",
            userType: this.ConnecteduserType,
            LogLevel: arg.LogLevel,
            Message: window.location.href,
          }
        );
      };
      if (window.location.href.includes("/app/confirm")) {
        document.body.className = "";
        document.body.classList.add("confirm");
        foundStyle = "confirm";
        let link = document.createElement("link");
        link.href = "/StyleCss/confirm.css";
        link.rel = "stylesheet";
        document.head.appendChild(link);
      } else if (window.location.href.includes("rapports")) {
        document.body.className = "";
        document.body.classList.add("Multi-reports");
        foundStyle = "Multi-reports";
        let link = document.createElement("link");
        link.href = "/StyleCss/Multi-reports.css";
        link.rel = "stylesheet";
        document.head.appendChild(link);
      } else if (window.location.href.includes("onboarding")) {
        document.body.className = "";
        document.body.classList.add("onboarding");
        foundStyle = "onboarding";
        let link = document.createElement("link");
        link.href = "/StyleCss/onboarding.css";
        link.rel = "stylesheet";
        document.head.appendChild(link);
      } else if (window.location.href.includes("security")) {
        document.body.className = "";
        document.body.classList.add("security");
        foundStyle = "security";

        let link = document.createElement("link");
        link.href = "/StyleCss/security.css";
        link.rel = "stylesheet";
        document.head.appendChild(link);
      } else if (window.location.href.includes("notif")) {
        document.body.className = "";
        document.body.classList.add("notif");
        foundStyle = "notif";
        let link = document.createElement("link");
        link.href = "/StyleCss/notif.css";
        link.rel = "stylesheet";
        document.head.appendChild(link);
      } else if (window.location.href.includes("inventoryapp")) {
        document.body.className = "";
        document.body.classList.add("inventoryapp");
        foundStyle = "inventoryapp";
        let link = document.createElement("link");
        link.href = "/StyleCss/inventoryapp.css";
        link.rel = "stylesheet";
        document.head.appendChild(link);
      } else {
        document.body.className = "";
        genericLogAppInsight({
          methodeName: "RouterBind",
          Module: "Portal",
          Status: "Provessing",
          Feature: "Style",
          Action: "RouterBind",
          LogLevel: "Information",
          Message: "Pas de style trouvé",
        });
      }
      genericLogAppInsight({
        methodeName: "RouterBind",
        Module: "Portal",
        Status: "End",
        Feature: "Style",
        Action: "RouterBind",
        LogLevel: "Information",
        Message: foundStyle,
      });
    },     
     showSidebar(menu) {
      this.selectedMenu = menu;
       this.activeSidebar = true;
       this.sideBarMenu = menu;
       this.setDocumentTitle(menu, null);
       this.userInformation = false;
       this.OnClickNotification = false;
       let NumPage = 0;

         this.children = menu.inverseMenuParent;
         this.titleMenuFR = menu.menuTitle;
         this.titleMenuEN = menu.menuEnglishTitle;
      
       this.menuLink = menu.menuLink;

       if ((this.menuLink == null || this.menuLink == "") && this.children.length == 0)
       {
         return;
       }

       if (this.children.length == 0)
       {
         window.open(this.menuLink, '_blank');
         return;
       }

       if (this.menuLink != null) {
         window.location.href = this.menuLink;
         return;
       }            

       this.openDeconnxion = false;     
       if (this.menuLink != null) {
         for (let index = 0; index < this.children.length; index++) {
           if (this.children[index].menuLink == this.menuLink) {
             NumPage = index;
           }
         }
         
         this.titleSousMenuFR = this.children[NumPage].menuTitle;
         this.titleSousMenuEN = this.children[NumPage].menuEnglishTitle;
         this.openedLink = this.menuLink;
         this.$router.push(this.openedLink);
       }
        else {
         this.titleSousMenuFR = this.children[NumPage].menuTitle;
         this.titleSousMenuEN = this.children[NumPage].menuEnglishTitle;
         this.openedLink = this.children[NumPage].menuLink;
       }
       if (menu.id == "2613275e-c771-47ff-91c1-2cf35bd45de8") {
         NumPage = 1;
       }
       if (this.children[NumPage].forceRefresh == 1) {
         window.location.href = this.children[NumPage].menuLink;
       } 
       else if (this.children[NumPage].forceRefresh != 1 && (this.menuLink == null || this.menuLink == "")) 
       {
         this.$router.push(this.children[NumPage].menuLink);
       }
       
       localStorage.setItem("LinkPage", this.children[NumPage].menuLink);
     },
    AccesDenied() {
      this.hasNotAccess = true;
    },
    disconnect() {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/";
    },
    checkToken() {
      if (localStorage.getItem("adal.idtoken")) {
        sessionStorage.setItem(
          "adal.idtoken",
          localStorage.getItem("adal.idtoken")
        );
        this.loggedIn = true;
      } else if (sessionStorage.getItem("adal.idtoken")) {
        localStorage.setItem(
          "adal.idtoken",
          sessionStorage.getItem("adal.idtoken")
        );
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    },
    OnLoggedIn(e) {
      this.loggedIn = true;
      let tocken = sessionStorage.getItem("adal.idtoken");

      this.getDataFromTocken(tocken);
      if (sessionStorage.getItem("redirectUrl")) {
        window.location.href = sessionStorage.getItem("redirectUrl");
        sessionStorage.removeItem("redirectUrl");
      } else {
        this.$router.push("/");
      }
    },
    getDataFromTocken(seessionStorageVar) {
      let base64Url = seessionStorageVar.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      this.UserDataFromTocken = JSON.parse(jsonPayload);
      if (sessionStorage.getItem("isGigyaToken") == "true" || 
          this.UserDataFromTocken.email !== null && sessionStorage.getItem("isGigyaToken") == null ) {
        this.ConnectedUserEmail = this.UserDataFromTocken.email;
        this.checkInDWHOrganisation();
      }
    },
    checkInDWHOrganisation() {
      this.get(window.baseUrlTiers + this.getContactClaims)
        .then((res) => {
          if (res.data) {
            localStorage.setItem("connectedUserEmail", this.ConnectedUserEmail);
            this.InDWH = true;
            this.isLoadingRES = false;
            this.userType = res.data[0].contactType;
            localStorage.setItem("userType", this.userType);
            if (this.userType == "Collaborateur") {
              this.type = true;
              this.ConnectedUserType = "collab";
            } else if (this.userType == "Client") {
              this.type = false;
              this.isClient = true;
              this.ConnectedUserType = "tiers";
            } else {
              this.ConnectedUserType = "tiers";
            }
          } else {
            this.InDWH = false;
            this.isLoadingRES = false;
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.InDWH = false;
            this.isLoadingRES = false;
          }
          console.log(err);
        });
    },
    Consent(value) {
      this.$store.commit("UpdateConsent", value);
    },
  },
};
</script>

<style lang="scss">
.DPLayout {
  &.with-footer-hidden {
    height: 100%;
    overflow: hidden auto;
    .content {
      height: 100%;

      .Router-content {
        height: 100%;
        .layout-content {
          .v-application--wrap {
            min-height: unset !important;
            height: 100% !important;
          }
          .ButtonsContainer {
            width: calc(100% - 35px) !important;
          }
        }
      }
    }

    .footer-container {
      height: 127px;
      position: relative;
      .cgu-footer.footer.FooterContainer {
        position: absolute;
      }
    }
  }
}
@media print {
  .footer-container {
    display: none !important;
  }
  .sideBar {
    display: none !important;
  }
  .menu-container {
    display: none !important;
  }
}
.FooterContainer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  top: unset;
}
button:focus {
  outline: none !important;
}

.folder-client {
  border-radius: 0;
  border: unset;
  background-image: url("../public/Icons/folder-client+.svg");
}

.folder-client-selected {
  border-radius: 0;
  border: unset;
  background-image: url("../public/Icons/folder-client-selected.svg");
}

.presentation {
  border-radius: 0;
  border: unset;
  background-image: url("../public/Icons/presentation.svg");
}

.circle {
  border-radius: 0;
  border: unset;
  background-image: url("../public/Icons/circle.svg");
}

// css sous menu inventory app
@font-face {
  font-family: "fontello";
  src: url("./assets/font/fontello.eot?67221648");
  src: url("./assets/font/fontello.eot?67221648#iefix")
      format("embedded-opentype"),
    url("./assets/font/fontello.woff2?67221648") format("woff2"),
    url("./assets/font/fontello.woff?67221648") format("woff"),
    url("./assets/font/fontello.ttf?67221648") format("truetype"),
    url("./assets/font/fontello.svg?67221648#fontello") format("svg");
  font-style: normal;
}


@font-face {
  font-family: "fontello";
  src: url("./assets/fontr-redirect/fontello.eot?67221648");
  src: url("./assets/fontr-redirect/fontello.eot?67221648#iefix")
      format("embedded-opentype"),
    url("./assets/fontr-redirect/fontello.woff2?67221648") format("woff2"),
    url("./assets/fontr-redirect/fontello.woff?67221648") format("woff"),
    url("./assets/fontr-redirect/fontello.ttf?67221648") format("truetype"),
    url("./assets/fontr-redirect/fontello.svg?67221648#fontello") format("svg");
  font-style: normal;
}

@font-face {
  font-family: "fontello";
  src: url("./assets/font-confirm/fontello.eot?67221648");
  src: url("./assets/font-confirm/fontello.eot?67221648#iefix")
      format("embedded-opentype"),
    url("./assets/font-confirm/fontello.woff2?67221648") format("woff2"),
    url("./assets/font-confirm/fontello.woff?67221648") format("woff"),
    url("./assets/font-confirm/fontello.ttf?67221648") format("truetype"),
    url("./assets/font-confirm/fontello.svg?67221648#fontello") format("svg");
  font-style: normal;
}

@font-face {
  font-family: "fontello";
  src: url("./assets/font-fsChecker/fontello.eot?96622291");
  src: url("./assets/font-fsChecker/fontello.eot?96622291#iefix")
      format("embedded-opentype"),
    url("./assets/font-fsChecker/fontello.woff?96622291") format("woff"),
    url("./assets/font-fsChecker/fontello.ttf?96622291") format("truetype"),
    url("./assets/font-fsChecker/fontello.svg?96622291#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/opensans/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-menu"]:before,
[class*=" icon-menu"]:before {
  font-family: "fontello", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-menu-reply-icon-redirect:before {
  content: "\e800";
} /* '' */
.icon-menu-icon-icon-mail-resend:before {
  content: "\e800";
}
.icon-menu-presentation:before {
  content: "\e802";
}
/* '' */
.icon-menu-circle:before {
  content: "\e805";
}

/* '' */
.icon-menu-file-invoice:before {
  content: "\e800";
}

/* '' */
.icon-menu-folder-client:before {
  content: "\e803";
}

/* '' */
.icon-menu-icon-icon-mail-resend {
  border: none !important;
  margin-left: -6px;
  font-size: 13px;
  margin-top: 0px;
}
.icon-menu-reply-icon-redirect {
  border: none !important;
  margin-left: -6px;
  font-size: 10px;
  margin-top: -3px;
}
.icon-menu-circle {
  border: none !important;
  margin-left: -6px;
  font-size: 18px;
  margin-top: -5px;
}

/* '' */
.icon-menu-presentation {
  border: none !important;
  margin-left: -6px;
  font-size: 18px;
  margin-top: -5px;
}

/* '' */
.icon-menu-file-invoice {
  border: none !important;
}

/* '' */
.icon-menu-icon-folder {
  border: none !important;
  margin-left: -6px;
  font-size: 18px;
  margin-top: -5px;
}
.icon-menu-icon-folder:before {
  content: "\e804";
}
.icon-menu-notification-setting {
  border: none !important;
  margin-left: -6px;
  font-size: 18px;
  margin-top: -5px;
}
.icon-menu-notification-setting:before {
  content: "\e803";
}
.icon-menu-icon-info:before {
  content: "\e800";
}
.icon-menu-control-mail {
  border: none !important;
  margin-left: -6px;
  font-size: 18px;
  margin-top: -5px;
}
.icon-menu-control-mail:before {
  content: "\e801";
}

.icon-menu-icon-info {
  border: none !important;
  margin-left: -6px;
  font-size: 18px;
  margin-top: -5px;
}
.componentcontent > .v-application > .v-application--wrap {
  min-height: unset !important;
  height: auto !important;
}
.APPContainer {
  height: 100%;
}
.custom-home-footer {
  .FooterContainer {
    bottom: 0;
    left: 0;
    right: 0;
    top: unset;
  }
  .divCookie {
    bottom: 0 !important;
    position: fixed !important;
  }
}
.CookiesConatiner {
  bottom: 0 !important;
  position: fixed !important;
}
.FooterContainer {
  z-index: 1000 !important;
}
body,
html {
  height: 100%;
  min-height: 100%;
}
.LoaderBox {
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.LoaderBox .SpinnerHome {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20%;
}
//////////////////////////////cookiee///////////////
.body-connexion {
  margin-bottom: 35px !important;
}
.connexion-title {
  margin-bottom: 10px !important;
}
.login-label-login {
  margin-bottom: 8px !important;
}
.gigya-screen.landscape
  .gigya-layout-row.with-divider
  .gigya-composite-control.gigya-composite-control-submit {
  padding-bottom: 0 !important;
}
.gigya-screen .gigya-composite-control {
  padding-bottom: 0px !important;
}
.gigya-screen .gigya-composite-control.gigya-composite-control-link {
  padding-bottom: 0px !important;
}
.acceskpmg-connexion {
  margin-top: 0px !important;
}
.ConnexionPagecontainer {
  height: auto !important;
}
////
.secondparthome {
  padding-top: 10px !important;
  padding-bottom: 24px !important;
}
/////
.gigya-screen {
  padding-bottom: 25px !important;
}
.gigya-screen.landscape {
  padding-top: 20px !important;
}
#containerGigya_social_0_uiContainer {
  height: 80px !important;
}
.divCookie {
  height: 75px;
}
</style>
