<template>
    <!-- home page -->
    <div
      :class="openDeconnxion ? 'HomePageExiste' : ''"
      @click="closePopups()"
    >
      <span class="name"
        >{{ $t("home.Bonjour") }} {{ ConnectedUserFirstName }}
        {{ ConnectedUserLastName }}, {{ $t("home.selectApp") }} :</span
      >
      <div class="AppContent">
        <div
          class="appContainer"
          v-for="item in apps"
          :key="item.id"
          @mouseover="isHover($event)"
          @mouseleave="isLeave()"
          @click="sendSelectedMenuToParent(item)"
        >
          <div
            class="appsBackGroundHome"
            v-bind:style="{ background: item.menuColor }"
          >
            <img v-bind:src="item.menuIcon" class="homeIcons" alt />
          </div>
          <div class="appsDescriptionHome">
            <div class="appsTitleHomePage" v-if="ChangedLang == 'fr'">
              {{ item.menuTitle }}
            </div>
            <div class="appsTitleHomePage" v-if="ChangedLang == 'en'">
              {{ item.menuEnglishTitle }}
            </div>
            <div class="descHomePage" v-if="ChangedLang == 'fr'">
              {{ item.menuDescription }}
            </div>
            <div class="descHomePage" v-if="ChangedLang == 'en'">
              {{ item.menuEnglishDescription }}
            </div>
          </div>
          <button class="ToTheApp">{{ $t("home.AccedApp") }}</button>
        </div>
      </div>
    </div>
</template>

<script>
import $ from "jquery";
import api from "../../../../Blob/KPMG.FR.ITS.DISCOVERY.PORTAL.APP.VUE/src/mixins/api";
import endPoints from "../../../../Blob/KPMG.FR.ITS.DISCOVERY.PORTAL.APP.VUE/src/mixins/endPoints";

export default {
  name: "Home",
  mixins: [api, endPoints],
  props: {
    language: String,
    UpdateUserInfo: Boolean,
  },
  watch: {
    UpdateUserInfo(){
      this.GetUserInfos();
    },
  },
  data() {
    return {
      apps: [],
      isRenewInformation: false,
      pagePath: "",
      scopesData: [],
      ConnecteduserType: "",
      userInformation: false,
      appsColumns: 0,
      titleSousMenuFR: "",
      titleSousMenuEN: "",
      baseUrlPortal: window.baseUrlPortal,
      baseUrlAuthorisation: window.baseUrlAutorisation,
      UrlTiers: window.baseUrlTiers,
      menuLink: "",
      openedLink: "",
      type: false,
      userType: "",
      selectedLanguage: "",
      ConnectedUserTitre: "",
      NouvelOnBoarding: "",
      ShowLogOutDropDown: false,
      openDeconnxion: false,
      ConnectedFullName: "",
      searchValAPP: "",
      searchApplication: "",
      sideBarClosed: false,
      children: [],
      openMenu: false,
      imgs: "/Icons/surface1.svg",
      refresh: 0,
      ConnectedUserFirstName: "",
      ConnectedUserLastName: ""
    };
  },
  computed:{
    ChangedLang(){
      return this.language;
    }
  },
  methods: {
    GetUserInfos(){
      this.ConnectedUserFirstName = localStorage.getItem('connectedUserFirstName');
      this.ConnectedUserLastName = localStorage.getItem('connectedUserLastName');
    },
    closePopups() {
      this.userInformation = false;
      this.OnClickNotification = false;
      this.openDeconnxion = false;
    },
    getUserInformation() {
      this.userInformation = !this.userInformation;
      this.OnClickNotification = false;
      this.openDeconnxion = false;
    },
    isHover(ev) {
      if ($(ev.target).hasClass("appContainer")) {
        $(".appContainer").removeClass("appContainerHover");
        $(ev.target).addClass("appContainerHover");
        $(".ToTheApp").removeClass("showBtn");
        $(ev.target)
          .find("button")
          .addClass("showBtn");
      }
    },
    isLeave() {
      $(".appContainer").removeClass("appContainerHover");
      $(".ToTheApp").removeClass("showBtn");
    },
    sendSelectedMenuToParent(menu){
      if (menu.menuCode == "M03") {
         menu = this.apps.find((x)=> {
           return x.menuCode == "DSHB20"
         });
       }
      this.$store.commit('UpdateSelectedMenu', menu);
      this.$emit('showSidebar', menu);
    },
    getMenuItemsToSidebar() {
      return this.get(this.baseUrlPortal + this.getMenuItemsToSidebarURL)
        .then((response) => {
          this.apps = response.data.value;
          this.appsColumns = Math.ceil(this.apps.length / 2);
          let url = window.location.pathname;
          let currentMenu;
           if (
                window.location.href.includes("rapport?path=Discovery%2FOn-boarding%2FRapportOnboarding")
              ) {
                this.children = this.apps.find((x) => {
                  return x.inverseMenuParent.some((e) => {
                    return e.menuCode == "M0503";
                  });
                });
                this.showActiveLink(this.children);
              }
          else {
              currentMenu = this.apps.find((acc) => {
                if (url === "/DynamicReports") return acc.menuCode === "DSHB20";

                return acc.inverseMenuParent.some(
                  (a) =>
                   decodeURI(a.menuLink).split("?")[0] ==
                   decodeURI(url.concat(window.location.search).split("?")[0])
                );
              });
            if (currentMenu != undefined) {
              let currentSubMenu = currentMenu.inverseMenuParent.find(
                (a) =>
                decodeURI(a.menuLink) ==
                decodeURI(url.concat(window.location.search))
              );

              if (!currentSubMenu)
                currentSubMenu = currentMenu.inverseMenuParent.find(
                  (a) =>
                  decodeURI(a.menuLink) ==
                  decodeURI(url.concat(window.location.search)) ||
                  decodeURI(a.menuLink).split("?")[0] ==
                  decodeURI(url.concat(window.location.search).split("?")[0])
                );

              this.children = currentMenu.inverseMenuParent;

              this.openedLink = currentSubMenu.menuLink;

              this.showActiveLink(currentMenu, this.openedLink);
            } else {
              if (
                window.location.href.includes(
                  "/rapport?path=Discovery%2FApplication%2FV%C3%A9rification%20Email"
                )
              ) {
                this.children = this.apps.find((x) => {
                  return x.inverseMenuParent.some((e) => {
                    return e.menuCode == "ADM0.5";
                  });
                });
                this.showActiveLink(this.children);
              }
              
              if (
                window.location.href.includes("/app/inventoryapp/AddInventaire")
              ) {
                this.children = this.apps.find((x) => {
                  return x.inverseMenuParent.some((e) => {
                    return e.menuCode == "INV01";
                  });
                });
                this.showActiveLink(this.children);
              }
                if (
                window.location.href.includes("/app/fschecker/audit-folders")
              ) {
                this.children = this.apps.find((x) => {
                  return x.inverseMenuParent.some((e) => {
                    return e.menuCode == "FSCK01";
                  });
                });
                this.showActiveLink(this.children);
                
              }
              if (window.location.pathname === "/bookmark") {
                this.children = this.apps[0].inverseMenuParent;

                if (this.children.length > 1) {
                  this.openedLink = this.children[2].menuLink;
                } else {
                  this.openedLink = this.children[0].menuLink;
                }
                this.showActiveLink(this.apps[0]);
              }
            }
          }
        })
        .catch((err) => {
          this.apps = err;
          this.$emit("AccesDenied");
        });
    },
    showActiveLink(ch, op) {
      this.titleMenuFR = ch.menuTitle;
      this.titleMenuEN = ch.menuEnglishTitle;
      this.children = ch.inverseMenuParent;
      this.openDeconnxion = false;
      this.userInformation = false;
      this.OnClickNotification = false;
      let newVal;
      if (op != null) {
        for (let index = 0; index < this.children.length; index++) {
          if (this.children[index].menuLink == op) {
            newVal = index;
          }
        }
        this.titleSousMenuFR = this.children[newVal].menuTitle;
        this.titleSousMenuEN = this.children[newVal].menuEnglishTitle;
      }
    },
  },
  created: function() {
    this.pagePath = window.location.pathname;
    this.ConnectedUserEmail = localStorage.getItem("connectedUserEmail");
    this.ConnecteduserType = localStorage.getItem("userType");
    if (window.location.pathname === "/sso") {
      this.$router.push("/");
    }
    this.getMenuItemsToSidebar();
  }
};
</script>
<style scoped>
.title-menu {
  height: 21px;
  top: 15px;
  padding-top: 10px;

  font-family: open sans, sans-serif;
  font-size: 20px;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  font-weight: normal;
  line-height: 21px;
  /* identical to box height, or 105% */

  /* KPMG / Basic / White */
  color: #ffffff;
}
.right-content-menu {
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  padding-right: 13px;
  height: 100%;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.left-content-menu {
  display: flex;
  justify-content: space-between;
  width: auto;
  height: 100%;
  padding-right: 24px;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  padding-left: 20px;
}
.user-title {
  color: white;
  font-size: 14px;
  font-family: open sans, sans-serif;
  font-weight: bold;
  margin-right: 11px;
  text-transform: capitalize;
}
.title-header {
  color: white;
  font-size: 8px;
  white-space: nowrap;
  display: block;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.LogOut {
  cursor: pointer;
  position: absolute;
  bottom: 80px;
  left: 4px;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
}
@media (max-width: 659px) {
  
  .content-title-menu {
    margin-left: 0px;
    width: 92%;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
  .user-title {
    align-self: center;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
  }
  .right-content-menu {
    right: 9px !important;
    top: 0px !important;
    height: 53px;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
  }
  .title-menu {
    font-size: 20px !important;
    text-align: center !important;
  }
}
@media (max-width: 375px) {
  .right-content-menu {
    left: 194px;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
  }
}
@media (max-width: 414px) {
  .right-content-menu {
    top: 0px !important;
    right: 9px;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
  }
  .title-menu {
    font-size: 20px !important;
    text-align: center !important;
  }
}
.user-menu {
  background: white;
  height: 34px;
  width: 34px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: open sans, sans-serif;
  font-weight: bold;
}
.menuPopUp {
  height: 396px;
  width: 951px;
  box-shadow: 0px 12px 24px #0000001a;
  position: absolute;
  top: calc(50% - 198px);
  left: calc(50% - 475px);
  border-top: #1070ca solid 4px;
  background-color: #fafafa;
  transition: all 2s;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.menuPopUpHeader {
  width: calc(100% - 32px);
  height: 64px;
  border-bottom: 1px solid #e4e7eb;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.menuPopUpTitle {
  font-weight: Bold;
  font-size: 24px;
  color: #425a70de;
  width: 64%;
  display: flex;
  justify-content: space-between;
  font-family: Open Sans, sans-serif;
}
.searchApps {
  width: 250px;
  height: 32px;
  background-color: #f5f6f7;
  border: 1px solid #e4e7eb;
  padding-left: 40px;
  color: #66788a;
  font-size: 14px;
  outline: none;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
}
.searchApps:focus {
  background-color: white;
}
.allAppsTitle {
  margin-top: 7px;
  margin-left: 16px;
  text-align: left;
  font: Bold 14px/23px Open Sans, sans-serif;
  color: #425a70de;
  cursor: pointer;
  border-bottom: dotted 1px #726e6e;
  width: 150px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.appsContainer {
  margin-top: -9px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  height: 308px;
}
.appsRow {
  margin-top: 24px;
  width: calc(100% - 36px);
  display: flex;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.appsCol {
  margin-left: 8px;
  width: 285px;
  height: 71px;
  box-shadow: 0px 1px 2px #0000001a;
  border: 1px solid #f0f3f6;
  padding-left: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.appsBackGround {
  width: 52px;
  height: 52px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.appsDescription {
  height: 50px;
  width: 190px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding-bottom: 2px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.appsTitle {
  font: Bold 12px/23px Open Sans, sans-serif;
  color: #425a70de;
  font-size: 12px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.desc {
  font: Regular 10px/13px Open Sans, sans-serif;
  font-size: 10px;
  color: #425a70de;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
}
.inpContainer {
  position: relative;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.searchIcon {
  width: 16px;
  position: absolute;
  top: 10px;
  left: 16px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  cursor: pointer;
}
.box-menu {
  cursor: pointer;
}
.closeIcon {
  width: 16px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  cursor: pointer;
}
.closeNotification {
  width: 16px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  cursor: pointer;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.closeSidebar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: #c4d4e8 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 12px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.left-arrow {
  width: 16px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.AppContent {
  max-height: calc(80vh - 150px);
  margin-top: 50px;
  overflow-y: auto;
  padding-left: 200px;
  width: 70%;
  min-height: 110px;
}
.name {
  color: #00338d;
  font-size: 22px;
  font-style: normal;
  font-family: open sans, sans-serif;
  font-weight: bold;
  padding-left: 200px;
  width: 70%;
}
.allAppsKpmg {
  font-size: 24px;
  margin-top: 51px;
  color: #425a70;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.appContainer {
  position: relative;
  border-bottom: 1px solid #e4e7eb;
  width: calc(100% - 55px);
  margin-top: 15px;
  height: 110px;
  padding-left: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.appContainerHover {
  background-color: #dbeaf754;
  border: 1px solid #1070ca;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.appsBackGroundHome {
  width: 78px;
  height: 78px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.homeIcons {
  width: 39px;
  height: 45px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.appsDescriptionHome {
  width: 500px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding-bottom: 2px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.ToTheApp {
  display: none;
  width: 176px;
  height: 40px;
  background-color: #1070ca;
  border: none;
  outline: none;
  margin-left: 20px;
  color: #ffffff;
  position: absolute;
  right: 16px;
  transition: all(1s);
  font-family: Open Sans, sans-serif;
  font-weight: 400;
}
.appsTitleHomePage {
  text-align: left;
  font-weight: bold;
  color: #425a70de;
  font-size: 20;
  font-family: open sans, sans-serif;
}
.descHomePage {
  color: #425a70de;
  font-family: open sans, sans-serif;
  font-weight: 400;
}
.showBtn {
  display: inline-block;
  transition: all(1s);
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.iconSidebar {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #1070ca;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.imgSideBarClosed {
  transform: rotate(180deg);
  transition-duration: 0.5s;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
}
.OpenPopUp {
  z-index: 100;
}
.logOutbtn {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
}
.logoutBtntext {
  width: 165px;
  position: absolute;
  left: 34px;
  text-align: left;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  margin-top: 3px;
  font-size: 16px;
}
.logOutIcon {
  margin-top: 7px;
}
.displayAffichageNotif {
  padding-top: 16px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.defaultNotifBackGround {
  background-color: #f3f2f5;
}

.readedNotifBackGround {
  background-color: white;
}
.NotificationTime {
  color: #6c757d !important;
  font-size: 12px;
  float: right;
}
.SeeMore {
  margin-left: 53px;
  font-size: 13px;
  color: #6e7687;
  margin-top: 10px;
  cursor: pointer;
  font-family: open sans, sans-serif;
}
.SeeMore :hover {
  color: #2185d0 !important;
}
.drobActive {
  max-height: 601px;
  overflow: hidden;
  overflow-y: scroll;
}
.boxDeconnexionClient {
  height: 248px;
  width: 238px;
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000022;
  border: 1px solid #e4e7eb;
  opacity: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  right: 10px;
  top: 80px;
  z-index: 2;
}
/******Responsive */
/******* *320> 480*/
@media (max-width: 480px) and (min-width: 320px) {
  .allAppsKpmg {
    font-size: 20px !important;
    margin-top: 22px !important;
  }
  .appContainer {
    padding-left: 3px !important;
    width: 500px !important;
    max-width: 100% !important;
    height: 90px;
  }
  .appsBackGroundHome {
    width: 105px !important;
    height: 56px !important;
  }
  .name {
    font-size: 22px;
  }
  /***pop up responsive 236 */
  .menuPopUp {
    height: 407px !important;
    width: 403px !important;
    left: calc(50% - 203px) !important;
    transition: none !important;
    overflow-x: scroll;
  }
  .appsRow {
    flex-direction: column !important;
  }
  .menuPopUpHeader {
    height: 98px !important;
  }
  .menuPopUpTitle {
    flex-direction: column !important;
  }
  .searchApps {
    width: 200px !important;
  }
  .appsCol {
    margin-left: 29px !important;
    margin-bottom: 11px !important;
  }
  .inpContainer {
    left: 11px !important;
    margin-top: 10px !important;
  }
  .allAppsTitle {
    margin-left: 30px !important;
  }
  .close {
    position: absolute;
    right: 17px;
    top: 17px;
  }
  .appsDescriptionHome {
    margin-left: 18px;
  }
  /*******responsive deconnexion */
  .boxDeconnexion {
    width: 201px !important;
    top: 99px !important;
    height: 214px !important;
  }
  .btnDeconnexion {
    width: 153px !important;
    height: 35px !important;
    top: 8px !important;
  }
  .title-deconnexion {
    font-size: 12px !important;
  }
}
/*********480> 640 */
@media (max-width: 648px) and (min-width: 480px) {
  .allAppsKpmg {
    font-size: 22px !important;
    margin-top: 22px !important;
  }
  .appContainer {
    position: relative !important;
    margin-top: 15px !important;
    height: 110px !important;
    padding-left: 3px !important;
    display: flex !important;
    align-items: center !important;
    cursor: pointer !important;
    width: 500px !important;
    max-width: 100% !important;
  }
  .appsBackGroundHome {
    width: 150px !important;
    height: 67px !important;
    border-radius: 5px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  /***pop up responsive */
  .menuPopUp {
    height: 396px !important;
    width: 95% !important;
    position: absolute !important;
    left: 2%;
    transition: none !important;
    overflow-x: scroll;
  }
  .appsRow {
    margin-top: 24px !important;
    width: calc(100% - 36px) !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .menuPopUpHeader {
    width: calc(100% - 32px) !important;
    height: 98px !important;
    display: flex !important ;
    justify-content: space-between !important;
    padding-left: 5px !important;
    padding-right: 16px !important;
    align-items: center !important;
  }
  .menuPopUpTitle {
    font-size: 20px !important;
    width: 76% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  .searchApps {
    width: 200px !important;
    height: 32px !important;
    padding-left: 40px !important;
    font-size: 14px !important;
    outline: none !important;
  }
  .appsCol {
    margin-left: 29px !important;
    margin-bottom: 11px !important;
    width: 479px;
  }
  .inpContainer {
    left: 11px !important;
    margin-top: 0px !important;
  }
  .allAppsTitle {
    margin-left: 30px !important;
  }
  .close {
    position: absolute;
    right: 17px;
    top: 17px;
  }
  .appsDescriptionHome {
    margin-left: 18px;
    font-size: 18px;
  }
  .desc {
    font-size: 14px;
  }
  .appsTitle {
    font-size: 16px;
  }
  /*******responsive deconnexion */
  .boxDeconnexion {
    width: 202px !important;
    top: 99px !important;
    height: 216px !important;
  }
  .btnDeconnexion {
    width: 153px !important;
    height: 35px !important;
    top: 10px !important;
  }
  .title-deconnexion {
    font-size: 12px !important;
  }
}

/*********640> 768 */
@media (max-width: 768px) and (min-width: 648px) {
  /******responsive home */
  .allAppsKpmg {
    font-size: 22px !important;
    margin-top: 22px !important;
  }
  .appContainer {
    position: relative !important;
    margin-top: 15px !important;
    height: 110px !important;
    padding-left: 3px !important;
    display: flex !important;
    align-items: center !important;
    cursor: pointer !important;
    width: 500px !important;
    max-width: 100% !important;
  }
  .appsBackGroundHome {
    width: 150px !important;
    height: 67px !important;
    border-radius: 5px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  /***pop up responsive */
  .menuPopUp {
    height: 396px !important;
    width: 95% !important;
    position: absolute !important;
    left: 2%;
    transition: none !important;
  }
  .appsRow {
    margin-top: 24px !important;
    width: calc(100% - 36px) !important;
    display: flex !important;
  }
  .menuPopUpHeader {
    width: calc(100% - 32px) !important;
    height: 98px !important;
    display: flex !important ;
    justify-content: space-between !important;
    padding-left: 11px !important;
    padding-right: 16px !important;
    align-items: center !important;
  }
  .menuPopUpTitle {
    font-size: 24px !important;
    width: 64% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  .searchApps {
    width: 221px !important;
    height: 32px !important;
    padding-left: 40px !important;
    font-size: 14px !important;
    outline: none !important;
  }
  .appsCol {
    margin-left: 11px !important;
    margin-bottom: 11px !important;
    width: 236px;
  }
  .inpContainer {
    left: 11px !important;
    margin-top: 0px !important;
  }
  .allAppsTitle {
    margin-left: 30px !important;
  }
  .close {
    position: absolute;
    right: 17px;
    top: 17px;
  }
  .appsDescriptionHome {
    margin-left: 18px;
    font-size: 18px;
  }
  /*******responsive deconnexion */
  .boxDeconnexion {
    width: 223px !important;
    height: 230px !important;
  }
  .btnDeconnexion {
    width: 153px !important;
    height: 35px !important;
    top: 12px !important;
  }
  .title-deconnexion {
    font-size: 12px !important;
  }
}
/**************768> 992 */
@media (max-width: 1024px) and (min-width: 768px) {
  /*******home responsive */
  .allAppsKpmg {
    font-size: 22px !important;
    margin-top: 22px !important;
  }
  .appContainer {
    position: relative !important;
    margin-top: 15px !important;
    height: 110px !important;
    padding-left: 3px !important;
    display: flex !important;
    align-items: center !important;
    cursor: pointer !important;
    width: 500px !important;
    max-width: 100% !important;
  }
  .appsBackGroundHome {
    width: 104px !important;
    height: 67px !important;
    border-radius: 5px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  /***pop up responsive */
  .menuPopUp {
    height: 396px !important;
    width: 95% !important;
    left: 2% !important;
    transition: none !important;
  }
  .appsRow {
    margin-top: 24px !important;
    width: calc(100% - 36px) !important;
    display: flex !important;
  }
  .menuPopUpHeader {
    width: calc(100% - 44px) !important;
    height: 98px !important;
    display: flex !important ;
    justify-content: space-between !important;
    padding-left: 27px !important;
    padding-right: 16px !important;
    align-items: center !important;
  }
  .menuPopUpTitle {
    font-weight: Bold !important;
    font-size: 20px !important;
    width: 64% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  .searchApps {
    width: 200px !important;
    height: 32px !important;
    padding-left: 40px !important;
  }
  .appsCol {
    margin-left: 18px !important;
    margin-bottom: 11px !important;
    width: 290px;
  }
  .inpContainer {
    left: 11px !important;
    margin-top: 0px !important;
  }
  .allAppsTitle {
    margin-left: 30px !important;
  }
  .close {
    position: absolute;
    right: 32px;
    top: 34px;
  }
  .appsDescriptionHome {
    margin-left: 18px;
  }
  /*******responsive deconnexion */
  .boxDeconnexion {
    width: 223px !important;
    height: 230px !important;
  }
  .btnDeconnexion {
    width: 153px !important;
    height: 35px !important;
    top: 12px !important;
  }
  .title-deconnexion {
    font-size: 12px !important;
  }
  .client-folder {
    width: 10px;
    height: 10px;
    background-image: url("../../public/Icons/folder-client+.svg");
  }

  .presentation {
    width: 10px;
    height: 10px;
    background-image: url("../../public/Icons/presentation.svg");
  }

  .circle {
    width: 10px;
    height: 10px;
    background-image: url("../../public/Icons/circle.svg");
  }
}
@media (max-width: 1920px) and (min-width: 1080px) {
  .AppContent {
    /* height: 700px; */
    margin-top: 50px;
    overflow-y: none;
  }
}
.notification-container {
  position: relative;
  width: 16px;
  height: 16px;
  top: 15px;
  left: 15px;
}
.notificationSystemAlert {
  height: 121px;
  padding: 12px 10px 12px 4px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  margin: 10px 3px 0 12px;
}

.notification-counter {
  position: absolute;
  top: -6px;
  left: 12px;
  background-color: rgba(212, 19, 13, 1);
  color: #fff;
  border-radius: 3px;
  padding: 1px 3px;
  font: 11px Verdana, sans-serif;
}
</style>
