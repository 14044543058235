export default {
  Status: {
    Status1: "Status1",
    Status2: "Status2",
  },
  LogApiResponseStatus: [400, 401, 500],
  LogStatus: { start: "Start", end: "End" },
  LogLevel: {
    error: "Error",
    verbose: "Verbose",
    critical: "critical",
    information: "Information",
  },
};
