window["aef-automate"] =  window['aef-automate']  ||  {}

export default {
    
    name:"ImportView",
       props: {
        requestId: {
            type: String,
            required: false
        }
    },
    components: {
    ...window["aef-automate"].default
  },
  data: () => ({
    language: ""
  }),
  mounted() {
    this.language = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;
  }
};
