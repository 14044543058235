import Vue from "vue";
import Router from "vue-router";

import Role from "@/components/Autorisation/Roles";
import Permissions from "@/components/Autorisation/Permissions";
import GestionMenuPage from "@/components/Autorisation/GestionMenuPage";
import APIMConsumersPage from "@/components/Autorisation/APIMConsumersPage";

import AccesDenied from "@/components/AccesDenied";

import Planning from "@/components/Multirapport2/Planning";
import TemplateManagementPage from "@/components/Multirapport2/TemplateManagementPage";
import Planning_RequestConsultation from "@/components/Multirapport2/Planning_RequestConsultation";
import Report_Publication from "@/components/Multirapport2/Report_Publication";
import Report_Consultation from "@/components/Multirapport2/Report_Consultation";
import Report_Viewer from "@/components/Multirapport2/Report_Viewer";

import DashboardView from "@/components/AEF_BI_Automate/DashboardView";
import AefFaqPage from "@/components/AEF_BI_Automate/AefFaqPage";

import ImportView from "@/components/AEF_BI_Automate/ImportView";
import ViewReportView from "@/components/AEF_BI_Automate/ViewReportView";
import DataSetAddUserStepper from "@/components/DatasetApp/DataSetAddUserStepper";

import ApplicationsPage from "@/components/Autorisation/ApplicationsPage";
import PublicationProjectPage from "@/components/Multirapports/PublicationProjectPage";
import ConsultationEditProjectPage from "@/components/Multirapports/ConsultationEditProjectPage";
import ConsultationProjectPage from "@/components/Multirapports/ConsultationProjectPage";
import ReportsPage from "@/components/Multirapports/ReportsPage";
import MyIndicatorsReport from "@/components/Multirapports/MyIndicatorsReport";
import SuiviFeesReport from "@/components/Multirapports/SuiviFeesReport";
import ReportingBODACC from "@/components/Multirapports/ReportingBODACCPage";
import BookmarkPage from "@/components/Multirapports/BookmarkPage";
import PublicationReportsPage from "@/components/Multirapports/PublicationReportsPage";
import AdministartionPage from "@/components/Multirapports/AdministartionPage";
import TreeviewMultiReportsPage from "@/components/Multirapports/TreeviewMultiReportsPage";

import OriginatingFeesReport from "@/components/Multirapports/OriginatingFeesReport";

import ViznetPage from "@/components/Viznet/ViznetPage";

import validatedByComPage from "@/components/EngagementFinder/validatedByComPage";
import RejectedByComPage from "@/components/EngagementFinder/RejectedByComPage";
import RejectedByManagerPage from "@/components/EngagementFinder/RejectedByManagerPage";
import PublishedPage from "@/components/EngagementFinder/PublishedPage";
import EngagementFinderPage from "@/components/EngagementFinder/EngagementFinderPage";
import AllPublishedPage from "@/components/EngagementFinder/AllPublishedPage";
import RelanceConfigurationPage from "@/components/EngagementFinder/RelanceConfigurationPage";

import AllInventairePage from "@/components/InventoryApp/AllInventairePage";
import EditeInventairePage from "@/components/InventoryApp/EditeInventairePage";
import AddInventairePage from "@/components/InventoryApp/AddInventairePage";
import DetailsInventairePage from "@/components/InventoryApp/DetailsInventairePage";
import EditeInventaireCPPage from "@/components/InventoryApp/EditeInventaireCPPage";

import AddNotification from "@/components/Notification/AddNotification";
import AddBnpNotification from "@/components/Notification/AddBnpNotification";
import ControleNotifications from "@/components/Notification/ControleNotifications";
import { Eventbus } from "../mixins/Bus";
import SignaturePdf from "@/components/Signature/SignaturePdf";
import DocsApiUploadPage from "@/components/Docs/DocsApiUploadPage";
import Home from "@/components/Home";
import store from "../store/index";
import { i18n } from '../i18n/i18n';

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: __dirname,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {layout: "no-sidebar"},
    },
    // Notification
    {
      path: "/notif/new",
      name: "AddNotification",
      component: AddNotification,
    },
    {
      path: "/notif/client/new",
      name: "AddBnpNotification",
      component: AddBnpNotification,
    },
    {
      path: "/notif/allnotif",
      name: "ControleNotifications",
      component: ControleNotifications,
    },
    //Signature
    {
      path: "/app/sign/new",
      component: SignaturePdf,
    },
    // inventory app
    {
      path: "/app/inventoryapp/engagements",
      component: AllInventairePage,
    },
    {
      path: "/app/inventoryapp/EditeInventaire",
      name: "EditeInventairePage",
      component: EditeInventairePage,
    },
    {
      path: "/app/inventoryapp/AddInventaire",
      name: "AddInventairePage",
      component: AddInventairePage,
    },
    {
      path: "/app/inventoryapp/EditeInventaireCP",
      name: "EditeInventaireCPPage",
      component: EditeInventaireCPPage,
    },
    {
      path: "/app/inventoryapp/DetailsInventaire",
      name: "DetailsInventaire",
      component: DetailsInventairePage,
    },
    //  acces denied
    {
      path: "/app/AccesDenied",
      component: AccesDenied,
    },
    {
      path: "/app/security/applications",
      component: ApplicationsPage,
    },
    {
      path: "/app/security/roles",
      component: Role,
    },
    {
      path: "/app/security/checkpermissions",
      component: Permissions,
    },
    {
      path: "/app/security/consommateur",
      component: APIMConsumersPage,
    },
    {
      path: "/app/menu/admin",
      component: GestionMenuPage,
    },
    // viznet
    {
      path: "/app/reseau",
      component: ViznetPage,
    },

    // multirapport
    {
      path: "/app/rapports/myindicators",
      component: MyIndicatorsReport,
    },

    {
      path: "/app/rapports/suivifees",
      component: SuiviFeesReport,
    },
    {
      path: "/app/rapports/originating",
      component: OriginatingFeesReport,
    },
    {
      path: "/app/rapports/reportingbodacc",
      component: ReportingBODACC,
    },
    {
      path: "/rapport",
      component: ReportsPage,
      props: true,
    },
    {
      path: "/bookmark",
      component: BookmarkPage,
      props: true,
    },
    {
      path: "/app/rapports",
      component: TreeviewMultiReportsPage,
    },
    {
      path: "/app/rapports/publie",
      component: PublicationReportsPage,
    },
    {
      path: "/app/rapportsprojets/new",
      component: PublicationProjectPage,
    },
    {
      path: "/app/rapportsprojets/edit",
      component: ConsultationEditProjectPage,
    },
    {
      path: "/app/rapportsprojets",
      component: ConsultationProjectPage,
    },
    {
      path: "/app/rapports/admin",
      component: AdministartionPage,
    },

    // engagement finder
    { path: "/app/egf/validatedByCom", component: validatedByComPage },
    { path: "/app/egf/RejectedByCom", component: RejectedByComPage },
    { path: "/app/egf/RejectedByManager", component: RejectedByManagerPage },
    { path: "/app/egf/Published", component: PublishedPage },
    { path: "/app/egf/EngagementFinder", component: EngagementFinderPage },
    { path: "/app/egf/AllPublished", component: AllPublishedPage },
    {
      path: "/app/egf/RelanceConfiguration",
      component: RelanceConfigurationPage,
    },
    //multirapportV2
    { path: "/app/dshb2/planning", component: Planning },
    { path: "/app/dshb2/Templates", component: TemplateManagementPage },
    { path: "/app/dshb2/requests", component: Planning_RequestConsultation },
    {
      path: "/app/dshb2/createpublication",
      component: Report_Publication,
      props: true,
    },
    {
      path: "/app/dshb2/publications",
      component: Report_Consultation,
      props: true,
    },
    {
      path: "/DynamicReports",
      component: Report_Viewer
    },
    //AEF BI Automate
    {
      path: "/app/eafautomate/dashboard",
      name: "AefDashboard",
      component: DashboardView,
    },
    {
      path: "/app/eafautomate/Aeffaq",
      name: "AefFaq",
      component: AefFaqPage,
    },
    {
      path: "/app/eafautomate/dashboard/report/:requestId",
      name: "AefViewReport",
      component: ViewReportView,
      props: true,
    },
    {
      path: "/app/aefautomate/import",
      name: "AefImport",
      component: ImportView,
      props: (route) => ({ requestId: route.query.requestId }),
    },
    // DataSEtApp
    {
      path: "/app/datasetapp/addTestUser",
      name: "DataSetAddUserStepper",
      component: DataSetAddUserStepper,
    },
    //Docs
    { path: "/app/docs/uploadFiles", component: DocsApiUploadPage },
  ],
});
router.beforeEach((to, from, next) => {
  if(store.state.selectedMenu)
  {
    if(to.path == "/")
    {
      document.title = "AppKPMG";
    }
    else {
      let currentSubMenu = store.state.selectedMenu.inverseMenuParent.find((x) => x.menuLink == to.fullPath );
      if(currentSubMenu)
      {    
        store.commit("UpdateSelectedSubMenu", currentSubMenu);
        let titleSousMenuFR = currentSubMenu.menuTitle;
        let titleSousMenuEN = currentSubMenu.menuEnglishTitle;
        if (!localStorage.getItem("UserLang")) 
          localStorage.setItem("UserLang", i18n.locale);
        let ChangedLang = localStorage.getItem("UserLang");
        if (ChangedLang == "fr") {
          document.title = store.state.selectedMenu.menuTitle + " - " + titleSousMenuFR;
        }
        if (ChangedLang == "en") {
          document.title = store.state.selectedMenu.menuEnglishTitle + " - " + titleSousMenuEN;
        }
      }
    }
  }
  
  if (to.query.pulsePath && to.query.pulseToken) {
    sessionStorage.setItem("adal.idtoken", to.query.pulseToken);
    localStorage.setItem("adal.idtoken", to.query.pulseToken);
    next();
  } else {
    let token = localStorage.getItem("adal.idtoken")
      ? localStorage.getItem("adal.idtoken")
      : sessionStorage.getItem("adal.idtoken");

    // verif if the user is Gigya user
    let isGigya = sessionStorage.getItem("isGigyaToken") == "true";
    if (token && !isGigya) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      let UserDataFromTocken = JSON.parse(jsonPayload);

      let expiration_timestamp = UserDataFromTocken.exp;

      if (Math.floor(Date.now() / 1000) > expiration_timestamp) {
        localStorage.removeItem("adal.idtoken");
        sessionStorage.removeItem("adal.idtoken");
        Eventbus.$emit("token_changed");
        next("/");
      }
    }
    next();
  }
});
export default router;